var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  var $bundleContext = $('.js-spp-bundle-layout');
  var $productKitButton = $('.js-bundle-get-set-cta', $bundleContext);
  var $bundleFlowElement = $('.js-bundle-flow', $bundleContext);
  var $sppBundleProduct = $bundleFlowElement.find('.js-spp-bundle-layout__step', $bundleContext);
  var $filterShadedProduct = $sppBundleProduct.filter('[data-product-isshaded="1"]');
  var nonShoppableProductCount = 0;
  var shadedProductOutOfStockCount = 0;
  var promotionalSkuInventoryStatus = 6;

  $filterShadedProduct.each(function () {
    var $singleProduct = $(this);
    var outOfStockShades = $singleProduct.find('li.shade-picker__outofstock').length;
    var swatchListItems = $singleProduct.find('li.js-swatch-list-item').length;

    if (outOfStockShades === swatchListItems) {
      $singleProduct.addClass('js-out-of-stock');
    }
    // popup next button out of stock
    $singleProduct.find('.js-bundle-next-cta').addClass('button--disabled');
  });
  $sppBundleProduct.each(function () {
    var $currentProduct = $(this);
    var isProductOrderable = $currentProduct.data('product-isorderable');
    var isProductShaded = $currentProduct.data('product-isshaded');
    var skuInventory = $('.js-product-gallery', $currentProduct).data('sku-inventory');

    if (
      isProductOrderable === 0
      && (isProductShaded === 0 && skuInventory !== promotionalSkuInventoryStatus)
    ) {
      nonShoppableProductCount++;
    }
    if ($currentProduct.hasClass('js-out-of-stock')) {
      shadedProductOutOfStockCount++;
    }
  });
  if (nonShoppableProductCount || shadedProductOutOfStockCount) {
    // landing page get the set button out of stock
    $productKitButton.addClass('button--disabled');
  }

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $swatchList = $('.js-swatch-list', $(this));

    if ($swatchList.length < 1) {
      return null;
    }
    Drupal.behaviors.swatchListV1.selectSku($swatchList, skuBaseId);
  });

  $(document).on('product.init', '.js-product', function (e) {
    var $swatchList = $('.js-swatch-list', $(this));

    if ($swatchList.length < 1) {
      return null;
    }
    Drupal.behaviors.swatchListV1.init($swatchList);
  });

  $(document).on('click', '.js-swatch-list-item-link', function (e) {
    e.preventDefault();
    var $swatchLink = $(this);
    var $swatchList = $(this).closest('.js-swatch-list');
    var selectedSkuBaseId = $swatchLink.attr('data-sku-base-id');

    Drupal.behaviors.swatchListV1.selectSku($swatchList, selectedSkuBaseId);
    var $productEl = prodcat.ui.getProductEl($swatchLink);

    $productEl.trigger('product.skuSelect', selectedSkuBaseId);
    // $productEl.trigger('product.skuDisplay', selectedSkuBaseId);

    // If mobile, scroll the page so smoosh image is in view.
    if (Unison.fetch.now().name == 'small') {
      var $galleryTop;
      var $parent = $(this).parents('.js-product');

      if ($('.js-product-gallery', $parent).length) { // On SPP
        $galleryTop = $('.js-product-gallery', $parent).offset().top - 50;
      } else if ($('.js-quickshop-smoosh-wrapper', $parent).length) { // On MPP quickshop
        $galleryTop = $('.js-quickshop-smoosh-wrapper', $parent).offset().top - 50;
      } else {
        $galleryTop = $parent.offset().top - 50;
      }

      if (!isNaN($galleryTop)) {
        $('html, body').stop().animate({ scrollTop: $galleryTop }, 500);
      }
    }
  });

  $(document).on('mouseout', '.js-swatch-list-item-link', function () {
    var $swatchList = $(this).closest('.js-swatch-list');
    var skuBaseId = $swatchList.data('sku-base-id');
    var $productEl = prodcat.ui.getProductEl($swatchList);

    $productEl.trigger('product.skuDisplay', skuBaseId);
  });

  $(document).on('mouseover', '.js-swatch-list-item-link', function () {
    var $swatchLink = $(this);
    var skuBaseId = $(this).data('sku-base-id');
    var $productEl = prodcat.ui.getProductEl($swatchLink);

    $productEl.trigger('product.skuDisplay', skuBaseId);
  });

  Drupal.behaviors.swatchListV1 = {
    attach: function (context) {
      var self = this;

      self.init($('.js-swatch-list', context));
    },

    init: function ($swatchLists) {
      var self = this;

      $swatchLists.each(function () {
        var $swatchList = $(this);
        var $swatches = $('.js-swatch-list-item-link', $swatchList);
        var prodId = $swatchList.data('product-id');

        // Multi-Sku Palette
        // set bgcolors of swatches to 1st Shade from each SKU
        if (prodcat.data.isPaletteMultiSku(prodId)) {
          var prodData = prodcat.data.getProduct(prodId);

          _.map(prodData.skus, function (sku, idx) {
            sku.HEX_VALUE_STRING = sku.HEX_VALUE_STRING.replace(/\,\s*/, '\,');
            var shades = sku.HEX_VALUE_STRING.split(',');
            var swatchHex = shades[0];

            $($swatches[idx]).css({ 'backgroundColor': swatchHex });
          });
        }
        // Multi-Product Palette
        // set bgcolors of swatches to 1st Shade from 1st SKU of each Product
        if (prodcat.data.isPaletteMultiProduct(prodId)) {
          var tempProd = $.extend(true, {}, prodcat.data.getProduct(prodId));
          var prods = prodcat.data.getPaletteMultiProducts();

          tempProd.skus = [];
          _.map(prods, function (prod, idx) {
            var sku = $.extend(true, {}, prod['skus'][0]);

            sku['HEX_VALUE_STRING'] = sku['HEX_VALUE_STRING'].split(/\,\s*/)[0];
            tempProd.skus[idx] = sku;
          });
          var html = site.template.get({
            name: 'swatch_list_uk_v1',
            data: tempProd
          });
          var $parent = $swatchList.parent();

          $swatchList.replaceWith(html);
          $swatchList = $('.js-swatch-list', $parent);
        }

        // var firstSkuBaseId = $(".js-swatch-list-item-link", $swatchList).first().attr('data-sku-base-id');
        // self.selectSku($swatchList, firstSkuBaseId);
        // Select first sku
        self.selectFirstSku($swatchList);

        // add gray border to light shades (lighter than  R: 233 G: 223 B: 245 or hex #E9DFF5)
        $swatches.each(function () {
          var $swatchLink = $(this);
          var rgbStr = $swatchLink.css('backgroundColor');
          var matches = rgbStr.match(/(\d*)\,\s(\d*)\,\s(\d*)/);

          // IE8 returns a hex value; other browsers return rgb. We're not drawing outlines in IE8 anyway, so return.
          if (!matches) {
            return null;
          }
          var red = parseInt(matches[1]);
          var green = parseInt(matches[2]);
          var blue = parseInt(matches[3]);

          if (red > 233 && green > 223 && blue > 245) {
            $swatchLink.addClass('light-shade');
          }
        });
      });
    },
    selectFirstSku: function ($swatchList) {
      var $links = $('.js-swatch-list-item-link', $swatchList);

      // return if any already selected ie. Shade Router
      if ($links.hasClass('selected')) {
        return null;
      }
      var firstSkuBaseId = $links.first().attr('data-sku-base-id');

      $(".js-swatch-list-item-link[data-sku-base-id='" + firstSkuBaseId + "']", $swatchList).addClass('selected');
    },
    selectSku: function ($swatchList, skuBaseId) {
      $swatchList.data('sku-base-id', skuBaseId);
      var $links = $('.js-swatch-list-item-link', $swatchList);

      $links.removeClass('selected');
      $(".js-swatch-list-item-link[data-sku-base-id='" + skuBaseId + "']", $swatchList).addClass('selected');
    }
  };
})(jQuery);
